import * as React from "react"

import Layout from "../../components/Layout/Layout"
import Projects from "../../components/Projects/Projects"
import SEO from "../../components/SEO/SEO"

const ProjectsPage = () => (
  <Layout>
    <SEO
      title="Projects of Ricardo Colin"
      description="Machine learning, backend, mobile, cloud and frontend projects of Ricardo Colin."
    />
    <Projects full={true} />
  </Layout>
)

export default ProjectsPage
